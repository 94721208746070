import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, first, skipWhile, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import UAParser from 'ua-parser-js';
import {
    selectCurrentUser,
    selectIsAuthenticated
} from '../auth/store/auth.selectors';
import { AppState } from '../core.state';
import { Logger } from '../logging/logger';
import { TelemetryEvent, TelemetryServiceResponse } from './telemetry.model';

@Injectable({ providedIn: 'root' })
export class TelemetryService {
    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
        private logger: Logger
    ) {}

    private getContext() {
        const userAgentParser = new UAParser();
        const browserInfo = userAgentParser.getResult();
        return {
            context: {
                platform: environment.appName,
                environment: environment.envName,
                'browser-info': browserInfo,
                deviceInfo: `${browserInfo.os.name} ${browserInfo.os.version}`,
                'screen-size': `${window.screen.width}x${window.screen.height}`,
                viewportSize: `${document.documentElement.clientWidth}x${
                    document.documentElement.clientHeight
                }`
            }
        };
    }

    sendActivity(verb: string, area: string, details: any = {}, verbDescription: string = '') {
        return this.sendActivity$(verb, area, details, verbDescription).subscribe();
    }

    sendActivity$(verb: string, area: string, details: any = {}, verbDescription: string = '') {
        return this.store.pipe(
            select(selectIsAuthenticated),
            skipWhile(isAuthenticated => !isAuthenticated),
            switchMap(() => this.store.pipe(select(selectCurrentUser))),
            filter(currentUser => !!currentUser),
            switchMap(currentUser => {
                const finalEvent: TelemetryEvent = {
                    verb: {
                        id: verb
                    },
                    actor: {
                        id: currentUser.userId,
                        role: 'teacher' // TODO: do we need to use a real value in Scout?
                    },
                    ...this.getContext(),
                    object: {
                        definition: {
                            name: area
                        },
                        extensions: {
                            ...details
                        }
                    },
                    published: new Date().toISOString()
                };
                if (verbDescription) {
                    finalEvent.verb.description = verbDescription;
                }
                this.logger.debug(
                    'ready to send final telemetry event: ',
                    finalEvent
                );

                this.logger.info('Skip sending telemetry event');
                return EMPTY;
            }),
            catchError(error => {
                this.logger.error('error sending telemetry event: ', error);
                return of(error);
            }),
            first(),
        );
    }

    private sendEvent(
        telemetryEvent: TelemetryEvent
    ): Observable<TelemetryServiceResponse> {
        const headers = {
            'x-api-key': environment.telemetry.key
        };
        return this.http.post<TelemetryServiceResponse>(
            environment.telemetry.url,
            telemetryEvent,
            { headers }
        );
    }
}
